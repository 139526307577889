<template>
  <ul @click="sideBarClick()">
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="$t(item)"
    />
    <span class="count-badge">{{ items[1].count }}</span>

  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";

export default {

  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    // count:{
    //   type: Number
    // }
  },
  setup() {
    provide("openGroups", ref([]));

    return {
      resolveNavItemComponent,
    };
  },

  methods: {
    sideBarClick() {
      this.$store.commit("app/ON_SIDEBAR_CLICK", true);
    },
  },
};
</script>
<style>
.count-badge {
  position: absolute;
  top: 55px;
  right: 125px;
  z-index: 9999;
  background-color: red;
  color: white;
  font-size: 9px;
  padding: 2px 5px;
  border-radius: 50%;
}
</style>